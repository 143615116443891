import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import WknPlyr from '../modules/wkn-plyr'
import WknSwiper from '../modules/wkn-swiper'
import gsap from 'gsap'

export default class PageHome extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {

    this.DOM = {
      scrollingText : viewStorage.current.querySelector('.Scrolling__text'),
      plyrs : viewStorage.current.querySelectorAll('.plyr__container'),
      partners: viewStorage.current.querySelector('.Partners__swiper .swiper')
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Page Home`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { scrollingText, plyrs, partners } = this.DOM
    scrollingText && this.initScrollingText()
    plyrs.length > 0 && this.initPlyrs()
    partners && this.initPartners()
  }

  initScrollingText() {
    const { scrollingText } = this.DOM
    const title = scrollingText.querySelector('.Scrolling__text__title')

    let mm = gsap.matchMedia();
    mm.add("(min-width: 768px)", () => {
      this.scrollingTextTL = gsap.timeline({
        defaults: { ease: 'none' },
        scrollTrigger: {
          trigger: title,
          start: 'center center',
          end: 'bottom bottom',
          endTrigger: scrollingText,
          scrub: true,
          markers: isDEVMODE
        }
      })
      .fromTo(title.querySelector('h2'), { yPercent: 0, scale: 1 }, { yPercent: 200, scale: 0.3 })
    })
  }

  initPlyrs() {
    const { plyrs } = this.DOM
    this.plyrs = []
    plyrs.forEach((p) => {
      const myPlyr = new WknPlyr(p)
      myPlyr.ST = ScrollTrigger.create({
        trigger: p.parentNode,
        onToggle: ({isActive}) => {
          if (isActive) {
            myPlyr.plyr.play()
          } else {
            myPlyr.plyr.stop()
          }
        }
      })

      this.plyrs.push(myPlyr)
    })
  }

  initPartners() {
    const { partners } = this.DOM
    this.partnersSwiper = new WknSwiper(partners)
  }

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    this.plyrs.forEach(plyr => {
      plyr.ST.kill(true)
      plyr.destroy()
    })
    this.scrollingTextTL && this.scrollingTextTL.kill(true)
  }
}
