/*
    Load Plugins / Functions
-------------------------------------------------- */
import { Core } from '@unseenco/taxi'
import { globalStorage } from './_globals'
import { onLeave, onEnter, onEnterCompleted } from './_events'

/* --- Renderers --- */
import Page from './pages/page'
import PageHome from './pages/pageHome'
import PageEcole from './pages/pageEcole'
import PagePedagogie from './pages/pagePedagogie'
import PageCandidater from './pages/pageCandidater'
import PageResources from './pages/pageResources'

/* --- Transitions --- */
import PageTransitionDefault from './transitions/page-transition-default'

/* --- Setup Core --- */
globalStorage.taxi = new Core({
  links: 'a:not([target]):not([href^=\\#]):not([data-taxi-ignore]):not(.ab-item)',
  renderers: {
    default: Page,
    pageHome: PageHome,
    pageEcole: PageEcole,
    pagePedagogie: PagePedagogie,
    pageCandidater: PageCandidater,
    pageResources: PageResources
  },
  transitions: {
    default: PageTransitionDefault
  }
})

/* --- Global Events --- */
globalStorage.taxi.on('NAVIGATE_IN', ({ to, trigger }) => onEnter(to, trigger))
globalStorage.taxi.on('NAVIGATE_OUT', ({ from, trigger }) => onLeave(from, trigger))
globalStorage.taxi.on('NAVIGATE_END', ({ to, from, trigger }) => onEnterCompleted(to, from, trigger))
